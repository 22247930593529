import React from 'react';
import { isBrowser } from '../lib/utils';
import { useEventListener } from './useEventListener';

export const useScrollPosition = () => {
	// if (!isBrowser()) return 0;

	// Store the state
	const [scrollPos, setScrollPos] = React.useState(window.pageYOffset);

	// On Scroll
	const onScroll = React.useCallback(() => {
		setScrollPos(window.pageYOffset);
	}, [setScrollPos, window.pageYOffset]);

	useEventListener('scroll', window, onScroll);

	return scrollPos;
};
