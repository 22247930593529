import { Button } from '@chakra-ui/react';
import * as React from 'react';
import {
	Close,
	Hamburger,
	HamburgerBgShape1,
	HamburgerBgShape2,
	HamburgerBgShape4,
	HamburgerBgShape5,
	HamburgerBgShape6,
	HamburgerBgShape7,
} from './Icons';

const hamburgerBgShapesArray = [HamburgerBgShape1, HamburgerBgShape2, HamburgerBgShape4, HamburgerBgShape5, HamburgerBgShape6, HamburgerBgShape7];

export const HamburgerButton: React.FC<{ active: boolean; onClick: () => void; isScrollingHamburger: boolean }> = (props) => {
	const randomNumber = Math.floor(Math.random() * hamburgerBgShapesArray.length);
	const [backgroundShapeNum, setBackroundShapeNum] = React.useState<number>(randomNumber);

	React.useEffect(() => {
		setBackroundShapeNum(randomNumber);
	}, []);

	const BackgroundShape = hamburgerBgShapesArray[backgroundShapeNum];

	return (
		<>
			{props.isScrollingHamburger ? (
				<Button
					className="btn btn-hamburger state-is-scrolling"
					height={{ base: '2.75em', sm: '3.2em' }}
					onClick={props.onClick}
					p="2"
					position="fixed"
					right={{ base: 4, sm: 6, md: 7, lg: 9 }}
					top={{ base: 0, sm: 3, md: 4, lg: 6 }}
					width={{ base: '2.75em', sm: '3.2em' }}
					zIndex="9100"
				>
					<BackgroundShape className="background-shape" width={'100%'} height={'100%'} /> {/* Background image */}

					{!props.active && <Hamburger className="icon icon-hamburger" position={'absolute'} />}

					{props.active && <Close className="icon icon-close" position={'absolute'} />}
				</Button>
			) : (
				<Button
					className="btn btn-hamburger"
					marginTop={'calc((1 - 1.4) * 1em)'}
					display={'block'}
					height={'unset'}
					padding={0}
					onClick={props.onClick}
					zIndex="1400"
				>
					{!props.active && <Hamburger className="icon icon-hamburger" />}

					{props.active && <Close className="icon icon-close" />}
				</Button>
			)}
		</>
	);
};
