import NextHead from 'next/head';
import * as React from 'react';

export const Head: React.FC<{
	title?: string;
	description?: string;
	twitter?: string;
	url?: string;
	image?: string;
	href?: string;
}> = (props) => {
	const url = props.href ? `${process.env.NEXT_PUBLIC_SITE_URL}${props.href}` : props.url;

	return (
		<NextHead>
			<title key="title">{props.title}</title>
			<meta name="description" content={props.description} />

			<meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:site" content={props.twitter} />
			<meta name="twitter:title" content={props.title} />
			<meta name="twitter:description" content={props.description} />
			<meta name="twitter:image" content={props.image} />

			<meta property="og:title" content={props.title} key="og:title" />
			<meta property="og:type" content="website" />
			<meta property="og:url" content={url} key="og:url" />
			<meta property="og:image" content={props.image} key="og:image" />
			<meta property="og:description" content={props.description} key="og:description" />

			<link rel="canonical" href={url} />
		</NextHead>
	);
};

export default Head;
