import { useEffect, useRef, useState } from 'react';

export const useAnimationFrame = (callback: (time: number, delta: number) => void, deps?: React.DependencyList) => {
	const frame = useRef(0);
	const last = useRef(performance.now());
	const init = useRef(performance.now());

	const animate = () => {
		const now = performance.now();
		const time = (now - init.current) / 1000;
		const delta = (now - last.current) / 1000;
		// In seconds ~> you can do ms or anything in userland
		callback(time, delta);
		last.current = now;
		frame.current = requestAnimationFrame(animate);
	};

	useEffect(() => {
		frame.current = requestAnimationFrame(animate);
		return () => cancelAnimationFrame(frame.current);
	}, deps); // Make sure to change it if the deps change
};

export const useAnimationTime = () => {
	const frame = useRef(0);
	const [value, setValue] = useState(0);

	const animate = (time: number) => {
		setValue(() => time);
		frame.current = requestAnimationFrame(animate);
	};

	useEffect(() => {
		frame.current = requestAnimationFrame(animate);
		return () => cancelAnimationFrame(frame.current);
	}, []);

	return value;
};
