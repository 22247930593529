import { useBreakpointValue } from '@chakra-ui/react';
import * as React from 'react';
import dynamic from 'next/dynamic';

const NextNprogress = dynamic(() => import('nextjs-progressbar'));

export const PageLoadingProgressBar = () => {
	const progressHeight = useBreakpointValue({ base: 3, sm: 4, md: 6 });
	return (
		<>
			<NextNprogress
				color="#000000"
				startPosition={0.3}
				stopDelayMs={200}
				height={progressHeight}
				options={{ trickleSpeed: 50, showSpinner: false }}
			/>
			<style>{`
				#nprogress .peg {
					box-shadow: unset !important;
					-webkit-transform: unset !important;
					-ms-transform: unset !important;
					transform: unset !important;
				}
			`}</style>
		</>
	);
};

export default PageLoadingProgressBar;
