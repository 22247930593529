import { useAnimationFrame } from '@hooks';
import { useCallback, useState } from 'react';

export function useMomentum(defaultTarget: number, { decay = 0.03, tolerance = 0.1 } = {}) {
	const [target, setTarget] = useState(defaultTarget);
	const [value, setValue] = useState(defaultTarget);

	const clear = useCallback(() => {
		setValue(target);
	}, [target, setValue]);

	useAnimationFrame(() => {
		const delta = target - value;
		let newValue = value + delta * decay;
		if (Math.abs(newValue - target) < tolerance) newValue = target;
		setValue(newValue);
	}, [value, target, decay]);

	return { value, set: setTarget, clear };
}
