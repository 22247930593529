import * as React from 'react';
import Head from '@components/Head';
import NextHead from 'next/head';
import PageLoadingProgressBar from '@components/PageLoadingProgressBar';
import dynamic from 'next/dynamic';
import { HamburgerButton } from '@components/HamburgerButton';
import { MotionBox } from '@components/MotionBox';
import { SiteMetadata } from '@lib/api';
import { useBreakpointValue, Box } from '@chakra-ui/react';
import { usePresence } from 'framer-motion';
import { useRouterScroll } from '@moxy/next-router-scroll';
import { useSiteContext } from '@hooks';

const AnimatedColorBackground = dynamic(() => import('@components/AnimatedColorBackground'), { ssr: false });
const Footer = dynamic(() => import('@components/Footer'));
const Header = dynamic(() => import('@components/Header'), { ssr: false });
const Navigation = dynamic(() => import('@components/Navigation'));
const PreviewRibbon = dynamic(() => import('@components/PreviewRibbon'), { ssr: false });
const Search = dynamic(() => import('@components/search'), { ssr: false });

type LayoutProps = {
	backgroundColor?: string;
	foregroundColor?: string;
	isHome?: boolean;
	isPreview?: boolean;
	showAnimatedGraidentBackground?: boolean;
	showFooter?: boolean;
	showFooterBackground?: boolean;
	siteMeta: SiteMetadata;
	title?: string;
};

const Layout: React.FC<LayoutProps> = (props) => {
	const [isPresent, safeToRemove] = usePresence();
	const breakpoint = useBreakpointValue<'mobile' | 'non-mobile'>({ base: 'mobile', sm: 'non-mobile' });
	const siteContext = useSiteContext();
	const { updateScroll } = useRouterScroll();

	React.useEffect(() => {
		if (!isPresent) {
			setTimeout(() => {
				safeToRemove();
			}, 500);
		}
	}, [isPresent]);

	React.useEffect(() => {
		updateScroll();
	}, []);

	return (
		<>
			<NextHead>
				<meta name="apple-mobile-web-app-capable" content="yes" />
				<meta name="viewport" content="user-scalable=no, width=device-width, initial-scale=1.0" />
			</NextHead>

			<Head
				description={props.siteMeta.site.description}
				image={`${process.env.NEXT_PUBLIC_SITE_URL}/social.png`}
				title={props.siteMeta.site.title}
				twitter={props.siteMeta.site.socialTwitterHandle}
				url={process.env.NEXT_PUBLIC_SITE_URL}
			/>

			<PageLoadingProgressBar />

			{props.isPreview && <PreviewRibbon />}

			<MotionBox
				initial="initial"
				animate="enter"
				exit="exit"
				variants={{
					initial: { y: 15, opacity: 0 },
					enter: { y: 0, opacity: 1, transition: { duration: 0.45, ease: [0.29, 0.01, 0.14, 1] } },
					exit: {
						opacity: 0,
						transition: { duration: 0.4, ease: [0.91, 0.01, 0.67, 1] },
					},
				}}
			>
				<Box as="main" background={props.backgroundColor} color={props.foregroundColor} overflow="hidden">
					{props.showAnimatedGraidentBackground && <AnimatedColorBackground />}

					<MotionBox
						initial="initial"
						animate="enter"
						exit="exit"
						className="header"
						height={'8.5em'}
						position={{ base: 'relative', lg: props.isHome ? 'absolute' : 'relative' }}
						right={0}
						top={0}
						left={0}
						variants={{
							initial: { opacity: 0 },
							enter: { opacity: 1, transition: { duration: 0.5, delay: 0.1 } },
							exit: {
								opacity: 0,
								transition: { duration: 0.4 },
							},
						}}
					>
						<Header />
					</MotionBox>

					{props.children}

					{props.showFooter && <Footer siteMeta={props.siteMeta} showBackground={props.showFooterBackground} />}
				</Box>
			</MotionBox>

			<Search
				allTags={props.siteMeta.allTags}
				featuredProjects={props.siteMeta.featuredFeed.items}
				searchResults={props.siteMeta.searchResults}
			/>

			<Navigation siteMeta={props.siteMeta} />

			{breakpoint == 'mobile' && (
				<HamburgerButton
					active={siteContext.isHamburgering}
					isScrollingHamburger
					onClick={!siteContext.isHamburgering ? siteContext.openHamburger : siteContext.closeHamburger}
				/>
			)}
		</>
	);
};

Layout.defaultProps = {
	isPreview: false,
	showFooter: true,
	showFooterBackground: true,
};

export default Layout;
