import isNumber from 'lodash/isNumber';
import React from 'react';

import { usePrevious } from './usePrevious';

function modPositive(a, n) {
	if (a == 0 || n == 0) return 0;
	return a - n * Math.floor(a / n);
}

export const useControllableInterval = ({
	startValue = 0,
	interval = 1000,
	loop,
	autoStart = true,
}: {
	autoStart?: boolean;
	startValue?: number;
	interval?: number;
	loop?: number;
} = {}) => {
	const [active, setActive] = React.useState(autoStart);
	const [value, setValue] = React.useState(startValue);
	const [hasManuallyStarted, setHasManuallyStarted] = React.useState(false);

	const timeoutId = React.useRef<NodeJS.Timeout>();

	const getNextValue = React.useCallback(
		(currentValue: number) => {
			if (isNumber(loop)) {
				return modPositive(currentValue + 1, loop);
			} else {
				return currentValue + 1;
			}
		},
		[loop],
	);

	const getPreviousValue = React.useCallback(
		(currentValue: number) => {
			if (isNumber(loop)) {
				return modPositive(currentValue - 1, loop);
			} else {
				return currentValue - 1;
			}
		},
		[loop],
	);

	const start = React.useCallback(() => {
		setHasManuallyStarted(true);
		setActive(true);
		timeoutId.current = setTimeout(() => setValue(getNextValue), interval);
	}, [timeoutId, setHasManuallyStarted, setActive]);
	const pause = React.useCallback(() => {
		clearTimeout(timeoutId.current);
		setActive(false);
	}, [timeoutId]);
	const stop = React.useCallback(() => {
		pause();
		setValue(0);
		setActive(false);
	}, [timeoutId, pause]);
	const reset = React.useCallback(() => setValue(startValue), [startValue]);
	const next = React.useCallback(() => setValue(getNextValue(value)), [value, loop]);
	const previous = React.useCallback(() => setValue(getPreviousValue(value)), [value, loop]);
	const isFirst = React.useMemo(() => value == 0, [value, loop]);
	const isLast = React.useMemo(() => (value != undefined ? value == loop - 1 : false), [value, loop]);
	const previousValue = usePrevious(value);
	const nextValue = React.useMemo(() => getNextValue(value), [value, loop]);
	const direction = React.useMemo<'left' | 'right'>(() => {
		if (previousValue == loop - 1 && value == 0) {
			return 'right';
		} else if (previousValue == 0 && value == loop - 1) {
			return 'left';
		} else {
			return value >= (previousValue ?? 0) ? 'right' : 'left';
		}
	}, [value, loop, previousValue]);

	React.useEffect(() => {
		// if (autoStart || hasManuallyStarted) start();
		if (autoStart) start();

		return () => pause();
	}, [value, hasManuallyStarted, getNextValue, interval]);

	const debugControls = (
		<>
			<span style={{ marginRight: '1em' }}>active: {active ? 'true' : 'false'}</span>
			<span style={{ marginRight: '1em' }}>previousValue: {previousValue}</span>
			<span style={{ marginRight: '1em' }}>value: {value}</span>
			<span style={{ marginRight: '1em' }}>loop: {loop}</span>
			<span style={{ marginRight: '1em' }}>direction: {direction}</span>
			<button onClick={start} style={{ marginRight: '1em' }}>
				start
			</button>
			<button onClick={pause} style={{ marginRight: '1em' }}>
				pause
			</button>
			<button onClick={reset} style={{ marginRight: '1em' }}>
				reset
			</button>
			<button onClick={next} style={{ marginRight: '1em' }}>
				next
			</button>
			<button onClick={previous} style={{ marginRight: '1em' }}>
				previous
			</button>
			<button onClick={stop}>stop</button>
		</>
	);

	return {
		active,
		value,
		pause,
		start,
		stop,
		reset,
		next,
		previous,
		loop,
		isFirst,
		isLast,
		previousValue,
		nextValue,
		direction,
		debugControls,
	};
};

export default useControllableInterval;
