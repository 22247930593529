import React from 'react';

export const useFetch = <T extends unknown>(url: string) => {
	const [state, setState] = React.useState<{ data?: T; loading: boolean; error?: any }>({ loading: true });

	React.useEffect(() => {
		fetch(url)
			.then((j) => j.json())
			.then((data) => {
				setState({ loading: false, data });
			})
			.catch((error) => {
				setState((state) => ({ ...state, error: `Error fetching ${url}: ${error}` }));
			});
	}, [url]);

	return { ...state };
};
